import React, { useState } from 'react';
import './enquiry.scss';

const Enquiry = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    category: '',
    date: '',
    movingTo: '',
    movingFrom: '',
    services: [],
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    // Update formData
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        services: checked
          ? [...prev.services, id]
          : prev.services.filter((service) => service !== id),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }

    // Clear the specific error if the field is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (id === 'name' && value) delete newErrors.name;
      if (id === 'email' && value) delete newErrors.email;
      if (id === 'mobile' && value) delete newErrors.mobile;
      if (id === 'category' && value !== 'Select your Category') delete newErrors.category;
      if (id === 'date' && value) delete newErrors.date;
      if (id === 'movingTo' && value) delete newErrors.movingTo;
      if (id === 'movingFrom' && value) delete newErrors.movingFrom;

      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Fill the Name';
    if (!formData.email) newErrors.email = 'Fill the Email';
    if (!formData.mobile) newErrors.mobile = 'Fill the Mobile number';
    if (!formData.category || formData.category === 'Select your Category') newErrors.category = 'Fill the Category';
    if (!formData.date) newErrors.date = 'Fill the Moving date';
    if (!formData.movingTo) newErrors.movingTo = 'Fill the Destination city';
    if (!formData.movingFrom) newErrors.movingFrom = 'Fill the Origin city';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const message = `
*Enquiry Details:*
- Name: ${formData.name}
- Email: ${formData.email}
- Mobile: ${formData.mobile}
- Category: ${formData.category}
- Moving Date: ${formData.date}
- Moving To: ${formData.movingTo}
- Moving From: ${formData.movingFrom}
- Services: ${formData.services.join(', ') || 'None'}
- Message: ${formData.message || 'No message'}
    `;

    const whatsappUrl = `https://wa.me/9600139400?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');

    alert("Your Enquiry has been successfully submitted!");
    setFormData({
      name: '',
      email: '',
      mobile: '',
      category: '',
      date: '',
      movingTo: '',
      movingFrom: '',
      services: [],
      message: '',
    });
  };

  return (
    <div className="py-5">
      <div className="form-container card">
        <h2>Enquire Us</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            placeholder="Enter your full name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span className="error text-start text-danger fs-6">{errors.name}</span>}

          <label htmlFor="email">E-mail Id:</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error text-start text-danger fs-6">{errors.email}</span>}

          <label htmlFor="mobile">Mobile No:</label>
          <input
            type="tel"
            id="mobile"
            placeholder="Enter your mobile number"
            value={formData.mobile}
            onChange={handleChange}
          />
          {errors.mobile && <span className="error text-start text-danger fs-6">{errors.mobile}</span>}

          <label htmlFor="category">Category:</label>
          <select id="category" value={formData.category} onChange={handleChange}>
            <option>Select your Category</option>
            <option>Household Shifting</option>
            <option>Office Relocation</option>
            <option>Car Transport</option>
          </select>
          {errors.category && <span className="error text-start text-danger fs-6">{errors.category}</span>}

          <label htmlFor="date">Moving Date:</label>
          <input
            type="date"
            id="date"
            value={formData.date}
            onChange={handleChange}
          />
          {errors.date && <span className="error text-start text-danger fs-6">{errors.date}</span>}

          <label htmlFor="movingTo">Moving To:</label>
          <input
            type="text"
            id="movingTo"
            placeholder="Destination city"
            value={formData.movingTo}
            onChange={handleChange}
          />
          {errors.movingTo && <span className="error text-start text-danger fs-6">{errors.movingTo}</span>}

          <label htmlFor="movingFrom">Moving From:</label>
          <input
            type="text"
            id="movingFrom"
            placeholder="Origin city"
            value={formData.movingFrom}
            onChange={handleChange}
          />
          {errors.movingFrom && <span className="error text-start text-danger fs-6">{errors.movingFrom}</span>}

          <label htmlFor="services">Additional Services:</label>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="packing"
              checked={formData.services.includes('packing')}
              onChange={handleChange}
            />
            <label htmlFor="packing">Packing</label>
            <input
              type="checkbox"
              id="insurance"
              checked={formData.services.includes('insurance')}
              onChange={handleChange}
            />
            <label htmlFor="insurance">Insurance</label>
          </div>

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            placeholder="Enter your message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>

          {/* <label htmlFor="upload">Upload File:</label>
          <input type="file" id="upload" /> */}

          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};

export default Enquiry;
