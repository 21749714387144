import React, { useState, useEffect } from 'react';
import './header.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Carousel, Row, Col } from 'react-bootstrap';
import '../../pages/home/home.scss';
import logo from '../../assets/logo/logo.png';
import TVSlogo from '../../assets/logo/tvs-logo.png';
import banner1 from '../../assets/truck-container-image/banner-image-1.jpeg';
import banner2 from '../../assets/truck-container-image/banner-2.png';
// import logo from '../../assets/logo/logo1.jpeg';
import { Link } from 'react-router-dom'; // Import Link
import Facebook from '../../assets/images/facebook.png';
import Instagram from '../../assets/images/instagram.png';
import Linkedin from '../../assets/images/linkedin.png';
import Telegram from '../../assets/images/telegram.png';
import Telephone from '../../assets/images/telephone.png';
import Twitter from '../../assets/images/twitter.png';
import Whatsapp from '../../assets/images/whatsapp.png';


const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  // Effect for Scroll Behavior
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle Navbar
  const toggleNavbar = () => {
    setIsNavbarCollapsed((prevState) => !prevState);
  };

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <div className={`header-top ${scroll ? 'scroll-hidden' : ''}`}>
        <div className="contact-info d-flex justify-content-center align-items-center gap-5">
          <a href="tel:+9600139400">
            <i className="bi bi-telephone-outbound-fill me-2 fs-6"></i>Call Us: +91 96001 39400
          </a>
          <a href="mailto:tvscopmtransports@gmail.com">
            <i className="bi bi-envelope me-2 fs-6"></i>Email: tvscopmtransports@gmail.com
          </a>
          <a href="#">
            <i className="bi bi-geo-alt-fill me-2 fs-6"></i>No: 15/8, SVS Nagar (3rd Street ), Valasaravaakam, Cheenai - 600 087
          </a>
        </div>
      </div>
      {/* <nav className={`navbar navbar-expand-lg d-block d-lg-none ${scroll ? 'navbar-scroll' : ''}`}>
        <div className="container">
        <div style={{ width: '30%' }}><a className="navbar-brand text-start" href="#" ><img src={logo} style={{ width: '50%' }} /></a></div>
          <button
            className={`navbar-toggler m-0 ${isNavbarCollapsed ? '' : 'collapsed'}`}
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">About Us</Link>
              </li>
              <li className="nav-item">
                <Link to="/services" className="nav-link">Services</Link>
              </li>
              <li className="nav-item">
                <Link to="/enquiry" className="nav-link">Enquiry</Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      <div className='d-block d-lg-none w-100' style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        zIndex: '999'
      }}>
        <header className="header text-start">
          {/* <div className="brand">BRAND</div> */}
          <div style={{ width: '40%' }}> <a className="navbar-brand text-start" href="/" > <img src={TVSlogo} style={{ width: '70%' }} /> </a> </div>
          <div className="menu-icon" onClick={handleMenuToggle}>
            &#9776;
          </div>
        </header>

        {/* Navbar */}
        <nav className={`navbar ${isNavbarOpen ? "active" : ""}`} style={{
          position: 'fixed',
          justifyContent: 'start',
          width: '250px',
          backgroundColor: "white",
          transition: 'all 0.3s ease-in-out'

        }}>
          <div className="close-icon" onClick={handleMenuToggle}>
            &times;
          </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/"  className="nav-link" onClick={handleMenuToggle}>Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" onClick={handleMenuToggle}>About Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link" onClick={handleMenuToggle}>Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/enquiry" className="nav-link" onClick={handleMenuToggle}>Enquiry</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" onClick={handleMenuToggle}>Contact Us</Link>
            </li>
          </ul>
          <div className='w-100 d-flex gap-3 p-2 pt-4 text-center justify-content-center' style={{ flexWrap: 'wrap' }}>
            <a target='_blank' href="https://www.facebook.com/profile.php?id=61566591563214"><img src={Facebook} style={{ width: '32px' }} /></a>
            {/* <a target='_blank' href="https://www.instagram.com/tvs_co_packers/" ><img src={Instagram} style={{ width: '32px' }} /></a> */}
            {/* <a href="#" ><img src={Linkedin} style={{width:'32px'}}/></a> */}
            {/* <a href="#" ><img src={Telegram} style={{width:'32px'}}/></a> */}
            <a target='_blank' href="tel:+9600139400" ><img src={Telephone} style={{ width: '32px' }} /></a>
            <a target='_blank' href="https://x.com/Tvs_Co_Packers" ><img src={Twitter} style={{ width: '32px' }} /></a>
            <a target='_blank' href="https://wa.me/9600139400" ><img src={Whatsapp} style={{ width: '32px' }} /></a>
          </div>
          <div className='copy-right'>
            <p>Copyright © 2024 All Rights Reserved By TVS PACKERS & MOVERS</p>
          </div>
        </nav>
      </div>

      <div style={{ position: 'relative' }}>
        <nav className={`navbar navbar-expand-md d-none d-lg-block ${scroll ? 'navbar-scroll' : ''}`} style={{
          position: 'absolute',
          left: '0',
          top: '0',
          zIndex: '9999',
          textAlign: 'center',
          width: '100%',
          // padding: '30px 0px',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '20px',
          fontWeight: '500'
        }}>
          <div className="container d-flex justify-content-center align-items-center">
            {/* <a className="navbar-brand" href="#">Navbar</a> */}
            <div style={{ width: '30%', textAlign: 'start' }}><a className="navbar-brand text-start" href="/" ><img src={TVSlogo} style={{ width: '40%' }} /></a></div>
            <button
              className={`navbar-toggler ${isNavbarCollapsed ? '' : 'collapsed'}`}
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarSupportedContent"
              aria-expanded={!isNavbarCollapsed}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isNavbarCollapsed ? '' : 'show'}`}
              id="navbarSupportedContent"
              style={{ width: '70%' }}
            >
              <ul className="navbar-nav ms-auto" style={{ display: 'flex', gap: '25px' }}>
                <li className="nav-item">
                  <Link to="/" className="nav-link">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link to="/services" className="nav-link">Services</Link>
                </li>
                <li className="nav-item">
                  <Link to="/enquiry" className="nav-link">Enquiry</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="hero-carousel-container mt-3 mt-lg-0">
          <Carousel className="custom-carousel mt-5 mt-lg-0" interval={2000} style={{ height: '50vh' }}>

            {/* Always Visible Carousel Item */}
            <Carousel.Item style={{ width: '100%', height: '100%' }}>
              <Carousel.Caption
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '0px',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  left: '0px',
                  right: '0px',
                  top: '0px',
                }}
              >
                <img
                  src="https://le-cdn.hibuwebsites.com/3860c1741d36427e8737b9feb971521c/dms3rep/multi/opt/home-image-1920w.jpg"
                  className="d-block w-100 h-100"
                  alt="Banner 1"
                />
                <div
                  style={{
                    position: 'absolute',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: '10px',
                    bottom: '30px',
                  }}
                >
                  <h3>We handle your move, like it’s ours</h3>
                  <p>Smart moving solutions for every journey</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            {/* Visible Only on Large Screens */}
            <Carousel.Item style={{ width: '100%', height: '100%' }}>
              <Carousel.Caption
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '0px',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  left: '0px',
                  right: '0px',
                  top: '0px',
                }}
              >
                <img
                  src="https://noorpackersandmovers.com/images/banner_3.jpg"
                  className="d-block w-100 h-100"
                  alt="Banner 2"
                />
                <div
                  style={{
                    position: 'absolute',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: '10px',
                    bottom: '30px',
                  }}
                >
                  <h3>We pack your dreams and deliver them securely</h3>
                  <p>Relocation without hesitation</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            {/* Visible Only on Small and Medium Screens */}
            <Carousel.Item style={{ width: '100%', height: '100%' }}>
              <Carousel.Caption
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '0px',
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  left: '0px',
                  right: '0px',
                  top: '0px',
                }}
              >
                <img
                  src={banner2}
                  className="d-block w-100 h-100"
                  alt="Banner 3"
                />
                <div
                  style={{
                    position: 'absolute',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    zIndex: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: '10px',
                    bottom: '30px',
                  }}
                >
                  <h3>Moving made simple, seamless, and stress-free!</h3>
                  <p>We pack, we move, you relax.</p>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default Header;
