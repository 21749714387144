import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/header';
import AboutUs from './pages/abouts';
import Footer from './components/footer';
import Home from './pages/home';
import Services from './pages/services';
import Enquiry from './pages/enquiry';
import Contact from './pages/contact';
import ScrollToTop from './components/common/headerScroll';

function App() {
  return (
    <div className="App">
      <Router>
        {/* Ensure ScrollToTop is within the Router */}
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
