import React, { useState } from "react";
import "./contact.scss";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);

    // Check if all fields are filled
    const isValid = Object.values(updatedFormData).every((field) => field.trim() !== "");
    setIsFormValid(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Format message for WhatsApp
    const whatsappMessage = `Hello, I am ${formData.name}.%0A
Subject: ${formData.subject}%0A
Message: ${formData.message}%0A
Email: ${formData.email}`;

    // Open WhatsApp with the formatted message
    const whatsappUrl = `https://wa.me/9600139400?text=${whatsappMessage}`;
    window.open(whatsappUrl, "_blank");

    // Show confirmation alert
    alert("Your Enquiry has been successfully submitted!");

    // Clear form fields
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });

    setIsFormValid(false);
  };

  return (
    <section className="ftco-section bg-light" id="contact">
      <div className="container">
        <h1 className="heading text-center mb-5">Contact Us</h1>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="wrapper px-md-4">
              <div className="row p-4 no-gutters">
                <div className="col-md-6 p-0">
                  <div className="contact-wrap w-100 p-md-5 p-4">
                    {/* <h3 className="mb-4">Contact Us</h3> */}
                    <form onSubmit={handleSubmit} id="contactForm" name="contactForm" className="contactForm">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group text-start">
                            <label className="label ms-1" htmlFor="name">Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group text-start">
                            <label className="label ms-1" htmlFor="email">Email Address</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-start">
                            <label className="label ms-1" htmlFor="subject">Subject</label>
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              id="subject"
                              placeholder="Subject"
                              value={formData.subject}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-start">
                            <label className="label ms-1" htmlFor="message">Message</label>
                            <textarea
                              name="message"
                              className="form-control"
                              id="message"
                              cols="30"
                              rows="4"
                              placeholder="Message"
                              value={formData.message}
                              onChange={handleInputChange}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!isFormValid}
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-6 p-0 order-md-first d-flex align-items-stretch" style={{ minHeight: "300px" }}>
                  <div id="map" className="map" style={{ position: "relative", overflow: "hidden" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7772.867149853506!2d80.20713869520948!3d13.071685199011744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266a26e195065%3A0x11382e6761fa01e8!2sArumbakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600106!5e0!3m2!1sen!2sin!4v1729516152639!5m2!1sen!2sin"
                      width="600"
                      height="100%"
                      style={{ border: "0" }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
