import React from 'react'
import './footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6 col-sm-6 col-lg-3 text-start'>
                        <h2 className='mb-4'>Links</h2>
                        <Link to="/" className='items-link'>Home</Link>
                        <Link to="/about" className='items-link'>About Us</Link>
                        <Link to="/services" className='items-link'>Services</Link>
                        <Link to="/enquiry" className='items-link'>Enquiry</Link>
                        <Link to="/contact" className='items-link'>Contact Us</Link>
                        <div className='mb-3 mb-lg-0 d-flex' style={{ display: "flex !important" }}>
                            <a target='_blank' href="https://www.facebook.com/profile.php?id=61566591563214"><i class="bi bi-facebook fs-4 me-4"></i></a>
                            <a target='_blank' href="https://x.com/Tvs_Co_Packers" ><i class="bi bi-twitter fs-4 me-4"></i></a>
                            <a target='_blank' href="https://wa.me/9600139400" ><i class="bi bi-whatsapp fs-4 me-4"></i></a>
                            {/* <a target='_blank' href="https://www.instagram.com/tvs_co_packers/" ><i class="bi bi-instagram fs-4 me-4"></i></a> */}
                            <a target='_blank' href="https://www.youtube.com/@tvscopmtransports2045" ><i class="bi bi-youtube fs-4 me-4"></i></a>
                        </div>

                    </div>
                    <div className='col-6 col-sm-6 col-lg-2 text-start'>
                        <h2 className='mb-4'>Branches</h2>
                        <p>Bangalore</p>
                        <p>Coimbatore</p>
                        <p>Hyderabad</p>
                        <p>Mumbai</p>
                    </div>
                    {/* <div className='col-12 mb-3 mb-lg-0 d-block d-sm-none'>
                        <i class="bi bi-facebook fs-4 me-4"></i>
                        <i class="bi bi-twitter fs-4 me-4"></i>
                        <i class="bi bi-whatsapp fs-4 me-4"></i>
                        <i class="bi bi-instagram fs-4 me-4"></i>
                        <i class="bi bi-youtube fs-4 me-4"></i>
                    </div> */}
                    <div className='col-12 col-sm-6 col-lg-3 text-start'>
                        <h2 className='mb-4'>Head Offices</h2>
                        <p>No: 15/8,<br />
                            SVS Nagar (3rd Street ),<br />
                            Valasaravaakam,<br />
                            Cheenai - 600 087</p>
                        <a href="tel:+9600139400">
                            <i className="bi bi-telephone-outbound-fill me-2 fs-6"></i>Call Us: +91 96001 39400
                        </a>
                        <a href="mailto:tvscopmtransports@gmail.com">
                            <i className="bi bi-envelope me-2 fs-6"></i>Email: tvscopmtransports@gmail.com
                        </a>
                        {/* <p>Shop: #25,<br />
                            Kundrathur Main Road,<br />
                            Porur, (Circle),<br />
                            Chennai – 600 116.</p> */}

                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 text-start'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.93372643968854!2d80.17257820687247!3d13.03951514022741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261243bf88d3d%3A0x7830d96c568f880c!2s11%2C%20Svs%20Nagar%20Main%20Rd%2C%20Subramanya%20Swamy%20Nagar%2C%20Sai%20Nagar%2C%20Chennai%2C%20Tamil%20Nadu%20600089!5e0!3m2!1sen!2sin!4v1733577061295!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer
