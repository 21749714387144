import React from 'react';
import './home.scss';
import AboutContent from '../../components/common/about';
import Video from '../../assets/videos/tvs-video.mp4';

const Home = () => {
    return (
        <>
            <div>
                <AboutContent />
                <div className='bg-dark1 p-4 p-md-5  mb-5'>
                    <div className="video-container">
                        <video
                            controls 
                            autoPlay
                            muted
                            loop
                            className="responsive-video"
                            title="How It Works">
                            <source src={Video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
