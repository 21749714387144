import React from 'react';
import Image1 from '../../../assets/truck-container-image/image-1.jpeg'

const AboutContent = () => {
    return (
        <>
            <div>
                {/* <div className="hero-carousel-container">
                    <Carousel className="custom-carousel" interval={2000}>
                        <Carousel.Item style={{ width: '100%', height: '100%' }}>
                            <Carousel.Caption style={{
                                width: '100%',
                                height: '100%',
                                padding: '0px',
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                textAlign: 'center',
                                left: "0px",
                                right: "0px"
                            }}>
                                <img src="https://noorpackersandmovers.com/images/banner_3.jpg" className="d-block w-100 h-100 slide-in back-image" alt="Banner 1" />
                                <div style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px'
                                }}>
                                    <h3 className="banner-title">Moving made simple, seamless, and stress-free!</h3>
                                    <p className="banner-description">We pack, we move, you relax.</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%', height: '100%' }}>
                            <Carousel.Caption style={{
                                width: '100%',
                                height: '100%',
                                padding: '0px',
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                textAlign: 'center',
                                left: "0px",
                                right: "0px"
                            }}>
                                <img src="https://noorpackersandmovers.com/images/banner_1.jpg" className="d-block w-100 h-100 slide-in" alt="Banner 2" />
                                <div style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px'
                                }}>
                                    <h3 className="banner-title">We handle your move, like it’s ours</h3>
                                    <p className="banner-description">Smart moving solutions for every journey</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item style={{ width: '100%', height: '100%' }}>
                            <Carousel.Caption style={{
                                width: '100%',
                                height: '100%',
                                padding: '0px',
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                textAlign: 'center',
                                left: "0px",
                                right: "0px"
                            }}>
                                <img src="https://noorpackersandmovers.com/images/banner_2.jpg" className="d-block w-100 h-100 slide-in" alt="Banner 2" />
                                <div style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px'
                                }}>
                                    <h3 className="banner-title">We pack your dreams and deliver them securely</h3>
                                    <p className="banner-description">Relocation without hesitation</p>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div> */}
                <div className='about container py-4'>
                    <h2 className='my-3 header'>ABOUT US PACKERS & MOVERS</h2>
                    <p className='text-start'>20 Years Oldest Company, Tvs Packers and Movers transport in Porur, Chennai, is a leading name in the transport and logistics industry. This well-established company serves customers both locally and from other parts of Chennai, offering comprehensive moving and packing solutions. Over the years, Tvs Packers and Movers has built a solid reputation, ensuring customer satisfaction is as important as the services provided, helping it expand its loyal client base, which continues to grow.</p>
                    <p className='text-start'>The team at Tvs Packers and Movers is dedicated to their roles, working tirelessly towards achieving the company's vision and larger goals. Looking ahead, the business plans to expand its range of services, reaching an even wider clientele.</p>
                    <p className='text-start'>Located in Porur, Chennai, Tvs Packers and Movers is easily accessible, with multiple modes of transport available. Situated on Kundrathur Main Road, near Chettayaragaram main road , it’s a convenient spot for first-time visitors. This trusted establishment provides exceptional service in the following categories: Transporters, Logistic Services, Cargo Services, All India Transporters, Parcel Booking Services for South India, and Tamil Nadu Transporters.</p>
                    <div className='row my-5'>
                        <div className='col-12 col-md-5 col-lg-6'><img src={Image1} className='w-100 h-100' /></div>
                        <div className='col-12 col-md-7 col-lg-6'>
                            <h2 className='text-start mt-5 mt-md-0'>COMPANY AND TRANSPORT</h2>
                            <p className='text-start'>Our company is a trusted and well-established leader in the moving industry, known for delivering top-notch relocation services to both individuals and businesses. With a rich history of excellence, we take pride in offering seamless moving experiences backed by a commitment to quality and care.
                                We boast a large fleet of modern, meticulously maintained vehicles equipped with state-of-the-art technology, ensuring the safe and efficient transport of your belongings. Over the years, we have built a solid reputation in the industry, driven by our unwavering belief that customer satisfaction is as vital as the services we provide.
                                This dedication to excellence has earned us a growing base of loyal customers who trust us to handle their moves with professionalism and precision. Our team of passionate and skilled professionals works tirelessly to fulfill the company’s vision, striving to exceed expectations and achieve common goals.
                                Choose us for a moving experience where your peace of mind is our priority!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutContent
