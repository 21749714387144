import React from 'react';
import AboutContent from '../../components/common/about';

const AboutUs = () => {
    return (
        <div>
            <AboutContent/>
        </div>
    )
}

export default AboutUs;
