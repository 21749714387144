import React from 'react';
import './services.scss';
import image1 from "../../assets/images/services/image-1.jpg";
import image2 from "../../assets/images/services/image-2.png";
import image3 from "../../assets/images/services/image-3.png";
import image4 from "../../assets/images/services/image-4.png";
import image5 from "../../assets/images/services/image-5.png";
import image6 from "../../assets/images/services/image-6.png";

const Services = () => {
  return (
    <div className='services py-5'>
      <div className='container'>
        <h2>Our Services</h2>
        <div className='row my-4'>
          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image1} />
                <p className='heading'>Moving and Unboxing Assistance</p>
              </div>

              <p className='text-start my-3'>
                TVS Packers and Movers in Chennai is your trusted partner for stress-free relocations, offering premium packing and moving services tailored to your needs. With a skilled team and a customer-first approach, we ensure a seamless and hassle-free moving experience every time!</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image2} />
                <p className='heading'>Car Shipping Solutions</p>
              </div>
              <p className='text-start my-3'>
                TVS Packers and Movers, Chennai, specializes in providing exceptional car transportation services designed for your peace of mind. With a focus on quality and care, we ensure your vehicle reaches its destination safely. Trust our expertise for a hassle-free moving experience!</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image3} />
                <p className='heading'>Travel and Transport Services</p>
              </div>
              <p className='text-start my-3'>
                We provide reliable surface transport with a dedicated fleet, ensuring safe and efficient goods movement. Trust us for seamless connectivity and expert service.</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image4} />
                <p className='heading'>Packing and Arranging Services</p>
              </div>
              <p className='text-start my-3'>
                We provide advanced, integrated packing and arranging solutions designed for efficiency, ensuring your items are securely packed and organized. Our innovative approach guarantees timely, reliable service to meet all your transportation needs.</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image5} />
                <p className='heading'>Cargo Transport Solutions</p>
              </div>
              <p className='text-start my-3'>
                Choose the right team for a smooth, stress-free house move. With top-quality service, we ensure a hassle-free relocation experience every time.</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card p-3'>
              <div style={{ position: 'relative' }}>
                <img src={image6} />
                <p className='heading'>Warehouse and Logistics Services</p>
              </div>
              <p className='text-start my-3'>
                Our expert team handles special materials with precision and care, ensuring complete inventory control from loading to secure storage in our facilities.</p>
              <div className='text-center'><button>Read More</button></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Services
